import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../global.service';
import * as M from "materialize-css/dist/js/materialize";
import * as $ from 'jquery';

@Component({
  selector: 'unos-izmjena-prvi-srednje',
  templateUrl: './unos-izmjena-prvi-srednje.component.html',
  styleUrls: ['./unos-izmjena-prvi-srednje.component.css']
})
export class UnosIzmjenaPrviSrednjeComponent implements OnInit {

	constructor(private http: HttpClient, public globalSrvc: GlobalService) { }

	@Input() id;
	@Input() novi;
	@Input() jedinica;
	@Input() prekoportala;

	@Output() onInsertISrednje = new EventEmitter<any>();
	@Output() onCancel = new EventEmitter<any>();

	prijavaskola : any = {'id' : '', 'idUstanove' : '', 'brojJedinice' : '', 'smjerId' : '', 'jmbg' : '', 'ime' : '', 'prezime' : '', 'opstina' : '', 'adresa' : '', 'telefon' : '', 'email' : '', 'datumRodjenja' : '', 'mjestoRodjenja' : '', 'pol' : '', 'imeOca' : '', 'imeMajke' : '', 'telefonOca' : '', 'telefonMajka' : '', 'emailOca' : '', 'emailMajke' : '', 'roditeljZiviId' : '', 'imeStaratelja' : '', 'idUstanoveOsnovno' : '', 'idGrupeStrani' : '', 'idGrupePrviStrani' : '', 'prekoPortala' : '', 'userId' : '', 'ustanoveIX' : [], 'grupeIStrani' : [], 'grupeIIStrani' : [], idJezika : '', jezici : [], 'etnika' : '', 'imaRjesenjeKat' : '', 'strucnaSpremaOca': '', 'jeOtacZaposlen': '', 'strucnaSpremaMajke': '', 'jeMajkaZaposlena': '', 'setoviizbornih' : [], 'idSeta1' : '', 'idSeta2' : '', 'idSeta3' : '', drzavaRodjenja : '', drzavljanstvo : ''};
	err_jmbg = false;
	err_jmbg_poruka = '';
	err_ime = false;
	err_ime_poruka = '';
	err_prezime = false;
	err_prezime_poruka = '';
	err_smjerId = false;
	err_smjerId_poruka = '';
	err_opstina = false;
	err_opstina_poruka = '';
	err_adresa = false;
	err_adresa_poruka = '';
	err_datumRodjenja = false;
	err_datumRodjenja_poruka = '';
	err_mjestoRodjenja = false;
	err_mjestoRodjenja_poruka = '';
	err_pol = false;
	err_pol_poruka = '';
	err_imeOca = false;
	err_imeOca_poruka = '';
	err_imeMajke = false;
	err_imeMajke_poruka = '';
	err_ustanova_poruka = '';
	err_ustanova = false; 
	err_jedinica_poruka = '';
	err_jedinica = false;
	err_telefonOca = false;
	err_telefonOca_poruka = '';
	err_emailOca = false;
	err_emailOca_poruka = '';
	err_ime_staratelja = false;
	err_ime_staratelja_poruka = '';
	err_prvi_strani = false;
	err_prvi_strani_poruka = '';
	err_drugi_strani = false;
	err_drugi_strani_poruka = '';
	err_jezik = false;
	err_jezik_poruka = '';	
	err_telefon = false;
	err_telefon_poruka = '';	
	err_idSeta1 = false;
	err_idSeta1_poruka = '';
	err_idSeta2 = false;
	err_idSeta2_poruka = '';
	err_idSeta3 = false;
	err_idSeta3_poruka = '';
	ustanoveIX : any = [];
	grupeIStrani : any = [];
	grupeIIStrani : any = [];
	jeziciStavke : any = [];
	setoviizbornih : any = [];

	saveDisabled = false;
	loading = false;
	msg : any;
	stepen : any = null;

	nivoKvalifikacije: any = [{ 'id': '', 'naziv': '', jeOdabran: false },
	{ 'id': 'Nepotpuna osnovna škola', 'naziv': 'Nepotpuna osnovna škola', jeOdabran: false },
	{ 'id': 'Osnovna škola', 'naziv': 'Osnovna škola', jeOdabran: false },
	{ 'id': 'Srednja škola', 'naziv': 'Srednja škola', jeOdabran: false },
	{ 'id': 'Viša škola', 'naziv': 'Viša škola', jeOdabran: false },
	{ 'id': 'Visoka škola', 'naziv': 'Visoka škola', jeOdabran: false },
	{ 'id': 'Magistar nauka', 'naziv': 'Magistar nauka', jeOdabran: false },
	{ 'id': 'Doktor nauka', 'naziv': 'Doktor nauka', jeOdabran: false }
	];

	ziviSaStavke : any = [{ 'id': '', 'naziv': '', jeOdabran: false }, { 'id': '1', 'naziv': 'Sa oba roditelja', jeOdabran: false }, { 'id': '2', 'naziv': 'Sa jednim roditeljem', jeOdabran: false }, { 'id': '3', 'naziv': 'Sa starateljem/hraniteljem', jeOdabran: false }, { 'id': '4', 'naziv': 'Dijete živi u ustanovi za nezbrinutu djecu', jeOdabran: false }];
	
	jmbgvalid = false;
	skole : any = [];
	jedinice : any = [];
	smjerovi : any = [];
	smjerovi_gimn : any = [];
	smjerovi_izuzetak : any = [];

	ngOnInit() {
		this.ucitaj();
		this.smjerovi_gimn.push({id : '97', naziv : 'OPŠTA GIMNAZIJA'});
		this.smjerovi_izuzetak.push({id : '6785', naziv : 'ELEKTROTEHNIČAR ZA RAZVOJ VEB I MOBILNIH APLIKACIJA'});
		let elems = document.querySelectorAll('.tooltipped');
		let instances = M.Tooltip.init(elems, {});
		//console.log('elems', elems);
		elems = document.querySelectorAll('.datepicker');
      	instances = M.Datepicker.init(elems, { 
			firstDay: true, 
			format: 'dd.mm.yyyy',
			i18n: {
				months: ["Januar", "Februar", "Mart", "April", "Maj", "Jun", "Jul", "Avgust", "Septembar", "Oktobar", "Novembar", "Decembar"],
				monthsShort: ["Jan", "Feb", "Mar", "Apr", "Maj", "Jun", "Jul", "Avg", "Sep", "Okt", "Nov", "Dec"],
				weekdays: ["Nedjelja","Ponedjeljak", "Utorak", "Srijeda", "Četvrtak", "Petak", "Subota"],
				weekdaysShort: ["Ned","Pon", "Uto", "Sri", "Čet", "Pet", "Sub"],
				weekdaysAbbrev: ["N","P", "U", "S", "Č", "P", "S"]
			}
		});
	}

	jmbgfocuslost(event){
		//console.log('focus lost', this.prijavaskola.jmbg);
	}

	ucitajSkole() {
		this.loading = true;
		this.http.get('prijave/skole', { params: { id: this.id } })
		.subscribe(data => {
			this.loading = false;
			this.skole = data;
			this.skole = this.skole.ustanove;
			//console.log(data);

			if (this.id)
				this.skolaselected(null);
		}
		, error => {
			this.loading = false;
		});
	}

	ucitajJezike() {
		this.loading = true;
		this.jeziciStavke = [];

		this.http.get('prijave/skola/jezici', { params: { idUstanove: this.prijavaskola.idUstanove} })
		.subscribe(data => {
			this.loading = false;
			this.jeziciStavke = data;

			if (this.jeziciStavke && this.jeziciStavke.length == 1)
				this.prijavaskola.idJezika = this.jeziciStavke[0].id;
			else 
				this.prijavaskola.idJezika = null;
		}
		, error => {
			this.loading = false;
		});
	}

	ucitaj() {
		this.loading = true;
		this.http.get('prijave/traziprijavuisrednje', { params: { id: this.id } })
			.subscribe(data => {
				this.loading = false;
				this.prijavaskola = data;	
				//console.log('this.prijavaskola', this.prijavaskola);
				this.ustanoveIX = this.prijavaskola.ustanoveIX;
				this.prijavaskola.ustanoveIX = [];
				this.grupeIStrani = this.prijavaskola.grupeIStrani;
				this.prijavaskola.grupeIStrani = [];
				this.grupeIIStrani = this.prijavaskola.grupeIIStrani;
				this.prijavaskola.grupeIIStrani = [];
				this.skole = this.prijavaskola.ustanoveSrednje;
				this.prijavaskola.ustanoveSrednje = [];
				this.jeziciStavke = this.prijavaskola.jezici;
				this.prijavaskola.jezici = [];
				this.setoviizbornih = this.prijavaskola.setovi; 
				this.prijavaskola.setoviizbornih = [];

				if (this.id){
					let skola = this.skole.find(item => item.id == this.prijavaskola.idUstanove);
					this.jedinice = skola.jedinice;
					this.ucitajsmjerove();
				}

				setTimeout(() => {
					M.updateTextFields();
				}, 100);
				
				//this.ucitajSkole();
			}
			, error => {
				this.loading = false;
			});
	}
	changedate(val){
		//console.log(val);
	}

	skolaselected(event){
		//console.log(this.prijavaskola.idUstanove, event);
		let skola = this.skole.find(item => item.id == this.prijavaskola.idUstanove);
		this.jedinice = skola.jedinice;

		if (!this.id){
			this.prijavaskola.brojJedinice = null;
			this.prijavaskola.smjerId = null;
			this.stepen = null;
			this.prijavaskola.idSeta1 = null;
			this.prijavaskola.idSeta2 = null;
			this.prijavaskola.idSeta3 = null;

			if (this.jedinice && this.jedinice.length == 1)
				this.prijavaskola.brojJedinice = this.jedinice[0].brojJedinice;
			else 
				this.prijavaskola.brojJedinice = null;
		}	
		this.ucitajsmjerove();
	}

	ucitajsmjerove(){
		this.loading = true;
		this.smjerovi = [];		

		this.http.get('prijave/srednja/smjerovi', { params: { idUstanove: this.prijavaskola.idUstanove } })
			.subscribe(data => {
				this.loading = false;
				this.smjerovi = data;
				//console.log(this.smjerovi);

				if (!this.id){
					/*if (this.smjerovi && this.smjerovi.length == 1)
						this.prijavaskola.smjerId = this.smjerovi[0].id;
					else 
						this.prijavaskola.smjerId = null;*/
					this.prijavaskola.smjerId = null;
					this.stepen = null;
				}

				if (!this.id)
					this.ucitajJezike();
			}
			,error => {
				this.loading = false;
			}
		);
	}

	vidjiljivismjerovi(){
		if (!this.prijavaskola.idUstanove || this.prijavaskola.idUstanove != '1035')
			return true;

		if (!this.prijavaskola.brojJedinice || this.prijavaskola.brojJedinice != '2')
			return true;

		return false;
	}

	smjeroviIzuzetak(){		
		if (!this.prijavaskola.idUstanove || this.prijavaskola.idUstanove != '1035')
			return false;

		this.getSmjeroviIzuzetak();
		return true;
	}

	getSmjeroviIzuzetak(){

		this.smjerovi_izuzetak = [];
		
		if (this.prijavaskola.idUstanove == '1035'){
			if (this.prijavaskola.brojJedinice == '1'){
				this.smjerovi_izuzetak = this.smjerovi.filter((item) => item.id != '6785');
			}
			else if (this.prijavaskola.brojJedinice == '2'){
				this.smjerovi_izuzetak.push({id : '6785', naziv : 'ELEKTROTEHNIČAR ZA RAZVOJ VEB I MOBILNIH APLIKACIJA'});
			}
		}
	}

	ucitajsetove(){
		this.loading = true;
		this.setoviizbornih = [];
		this.stepen = null;

		this.http.get('prijave/srednja/smjerovi/setovi', { params: { idUstanove: this.prijavaskola.idUstanove, idSmjera : this.prijavaskola.smjerId } })
			.subscribe(data => {
				this.loading = false;
				this.setoviizbornih = data;

				if (!this.id){
					/*if (this.smjerovi && this.smjerovi.length == 1)
						this.prijavaskola.smjerId = this.smjerovi[0].id;
					else 
						this.prijavaskola.smjerId = null;*/
					this.prijavaskola.idSeta1 = null;
					this.prijavaskola.idSeta2 = null;
					this.prijavaskola.idSeta3 = null;
				}
			}
			,error => {
				this.loading = false;
			}
		);
	}

	jeCetvrtiStepen(){
		let odabrani_smjer = null;
		odabrani_smjer = this.smjerovi.find(item => item.id == this.prijavaskola.smjerId);
		
		if (odabrani_smjer)
			return odabrani_smjer.stepen == 4;

		return false;;

	}

	printobj(){
		//console.log(this.prijavaskola);
	}

	toastTimeout = 3000;

	savedata() {
		try {
			/*if (this.prijavaskola.idUstanove == '1015' && this.prijavaskola.idJezika != '1'){
				if (this.prijavaskola.smjerId != '7854'){
					this.err_jezik = true;
					this.err_jezik_poruka = 'Ovaj nastavni jezik nije moguće odabrati za odabrani smjer';
					M.toast({html: this.err_jezik_poruka, displayLength : this.toastTimeout});
					return;
				}
			}

			if (this.prijavaskola.idUstanove == '11' && this.prijavaskola.idJezika != '1'){
				//cg-eng
				//if (this.prijavaskola.smjerId != '97')
				{
					this.err_jezik = true;
					this.err_jezik_poruka = 'Ovaj nastavni jezik nije moguće odabrati za odabrani smjer';
					M.toast({html: this.err_jezik_poruka, displayLength : this.toastTimeout});
					return;
				}
			}
			if (this.prijavaskola.idUstanove == '1008' && this.prijavaskola.idJezika != '1'){
				//cg-eng
				if (this.prijavaskola.smjerId != '6785'){
					this.err_jezik = true;
					this.err_jezik_poruka = 'Ovaj nastavni jezik nije moguće odabrati za odabrani smjer';
					M.toast({html: this.err_jezik_poruka, displayLength : this.toastTimeout});
					return;
				}
			}*/
			this.err_jezik = true;
			this.err_jezik_poruka = '';
			
			if (this.prijavaskola.idUstanove === undefined || this.prijavaskola.idUstanove == null || this.prijavaskola.idUstanove == '') {
				this.err_ustanova = true;
				this.err_ustanova_poruka = 'Morate odabrati ustanovu';
				M.toast({html: this.err_ustanova_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_ustanova = false;
			this.err_ustanova_poruka = '';

			if (this.prijavaskola.brojJedinice === undefined || this.prijavaskola.brojJedinice == null || this.prijavaskola.brojJedinice == '') {
				this.err_jedinica = true;
				this.err_jedinica_poruka = 'Morate odabrati jedinicu';
				M.toast({html: this.err_jedinica_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_jedinica = false;
			this.err_jedinica_poruka = '';

			if (this.prijavaskola.smjerId === undefined || this.prijavaskola.smjerId == null || this.prijavaskola.smjerId == ''){
				this.err_smjerId = true;
				this.err_smjerId_poruka = 'Morate definisati polje Smjer';
				M.toast({html: this.err_smjerId_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_smjerId = false;
			this.err_smjerId_poruka = '';

			if (this.prijavaskola.idJezika === undefined || this.prijavaskola.idJezika == null || this.prijavaskola.idJezika == '') {
				this.err_jezik = true;
				this.err_jezik_poruka = 'Morate definisati polje Nastavni jezik';
				M.toast({html: this.err_jezik_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_jezik = false;
			this.err_jezik_poruka = '';
			
			if (this.prijavaskola.jmbg === undefined || this.prijavaskola.jmbg == null || this.prijavaskola.jmbg == '') {
				this.err_jmbg = true;
				this.err_jmbg_poruka = 'Morate definisati polje JMB.';
				M.toast({html: this.err_jmbg_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_jmbg = false;
			this.err_jmbg_poruka = '';

			if (this.prijavaskola.ime === undefined || this.prijavaskola.ime == null || this.prijavaskola.ime == '') {
				this.err_ime = true;
				this.err_ime_poruka = 'Morate definisati polje Ime. Ovaj podatak se preuzima iz registara klikom na dugme pored polja JMB.';
				M.toast({html: this.err_ime_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_ime = false;
			this.err_ime_poruka = '';

			if (this.prijavaskola.prezime === undefined || this.prijavaskola.prezime == null || this.prijavaskola.prezime == '') {
				this.err_prezime = true;
				this.err_prezime_poruka = 'Morate definisati polje Prezime. Ovaj podatak se preuzima iz registara klikom na dugme pored polja JMB.';
				M.toast({html: this.err_prezime_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_prezime = false;
			this.err_prezime_poruka = '';

			/*if (this.prijavaskola.opstina === undefined || this.prijavaskola.opstina == null || this.prijavaskola.opstina == ''){
				this.err_opstina = true;
				this.err_opstina_poruka = 'Morate definisati polje Opština. Ovaj podatak se preuzima iz registara klikom na dugme pored polja JMB.';
				M.toast({html: this.err_opstina_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_opstina = false;
			this.err_opstina_poruka = '';

			if (this.prijavaskola.adresa === undefined || this.prijavaskola.adresa == null || this.prijavaskola.adresa == '') {
				this.err_adresa = true;
				this.err_adresa_poruka = 'Morate definisati polje Adresa. Ovaj podatak se preuzima iz registara klikom na dugme pored polja JMB.';
				M.toast({html: this.err_adresa_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_adresa = false;
			this.err_adresa_poruka = '';
			*/
			if (this.prijavaskola.datumRodjenja === undefined || this.prijavaskola.datumRodjenja == null) {
				this.err_datumRodjenja = true;
				this.err_datumRodjenja_poruka = 'Morate definisati polje Datum rođenja. Ovaj podatak se preuzima iz registara klikom na dugme pored polja JMB.';
				M.toast({html: this.err_datumRodjenja_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_datumRodjenja = false;
			this.err_datumRodjenja_poruka = '';

			if (this.prijavaskola.mjestoRodjenja === undefined || this.prijavaskola.mjestoRodjenja == null || this.prijavaskola.mjestoRodjenja == ''){
				this.err_mjestoRodjenja = true;
				this.err_mjestoRodjenja_poruka = 'Morate definisati polje Mjesto rođenja. Ovaj podatak se preuzima iz registara klikom na dugme pored polja JMB.';
				M.toast({html: this.err_mjestoRodjenja_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_mjestoRodjenja = false;
			this.err_mjestoRodjenja_poruka = '';

			if (this.prijavaskola.pol === undefined || this.prijavaskola.pol == null || this.prijavaskola.pol == '') {
				this.err_pol = true;
				this.err_pol_poruka = 'Morate definisati polje Pol. Ovaj podatak se preuzima iz registara klikom na dugme pored polja JMB.';
				M.toast({html: this.err_pol_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_pol = false;
			this.err_pol_poruka = '';

			/*if (this.prijavaskola.imeOca === undefined || this.prijavaskola.imeOca == null || this.prijavaskola.imeOca == ''){
				this.err_imeOca = true;
				this.err_imeOca_poruka = 'Morate definisati polje Ime oca';
				M.toast({html: this.err_imeOca_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_imeOca = false;
			this.err_imeOca_poruka = '';
	
			if (this.prijavaskola.imeMajke === undefined || this.prijavaskola.imeMajke == null || this.prijavaskola.imeMajke == ''){
				this.err_imeMajke = true;
				this.err_imeMajke_poruka = 'Morate definisati polje Ime majke';
				M.toast({html: this.err_imeMajke, displayLength : this.toastTimeout});
				return;
			}
			this.err_imeMajke = false;
			this.err_imeMajke_poruka = '';*/

			if (this.prijavaskola.telefon === undefined || this.prijavaskola.telefon == null || this.prijavaskola.telefon == ''){
				this.err_telefon = true;
				this.err_telefon_poruka = 'Morate definisati polje Broj telefona učenika';
				M.toast({html: this.err_telefon_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_telefon = false;
			this.err_telefon_poruka = '';

			if (this.prijavaskola.telefonOca === undefined || this.prijavaskola.telefonOca == null || this.prijavaskola.telefonOca == ''){
				this.err_telefonOca = true;
				this.err_telefonOca_poruka = 'Morate definisati polje Broj telefona roditelja/staratelja/hranitelja';
				M.toast({html: this.err_telefonOca_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_telefonOca = false;
			this.err_telefonOca_poruka = '';
	
			/*if (this.prijavaskola.emailOca === undefined || this.prijavaskola.emailOca == null || this.prijavaskola.emailOca == ''){
				this.err_emailOca = true;
				this.err_emailOca_poruka = 'Morate definisati polje E-mail roditelja/staratelja/hranitelja';
				M.toast({html: this.err_emailOca_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_emailOca = false;
			this.err_emailOca_poruka = '';
			*/

			if (this.prijavaskola.roditeljZiviId == '3'){
				if (this.prijavaskola.imeStaratelja === undefined || this.prijavaskola.imeStaratelja == null || this.prijavaskola.imeStaratelja == '') {
					this.err_ime_staratelja = true;
					this.err_ime_staratelja_poruka = 'Ime i prezime staratelja/hranitelja';
					M.toast({html: this.err_ime_staratelja_poruka, displayLength : this.toastTimeout});
					return;
				}
				this.err_ime_staratelja = false;
				this.err_ime_staratelja_poruka = '';
			}

			if (this.prijavaskola.idGrupePrviStrani === undefined || this.prijavaskola.idGrupePrviStrani == null || this.prijavaskola.idGrupePrviStrani == ''){
				this.err_prvi_strani = true;
				this.err_prvi_strani_poruka = 'Morate definisati polje Prvi strani jezik';
				M.toast({html: this.err_prvi_strani_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_prvi_strani = false;
			this.err_prvi_strani_poruka = '';

			if (this.prijavaskola.idGrupeStrani === undefined || this.prijavaskola.idGrupeStrani == null || this.prijavaskola.idGrupeStrani == ''){
				this.err_drugi_strani = true;
				this.err_drugi_strani_poruka = 'Morate definisati polje Drugi strani jezik';
				M.toast({html: this.err_drugi_strani_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_drugi_strani = false;
			this.err_drugi_strani_poruka = '';

			if (this.jeCetvrtiStepen()){
				if (!this.prijavaskola.idSeta1 && !this.prijavaskola.idSeta2 && !this.prijavaskola.idSeta3){	
					this.err_idSeta1 = true;
					this.err_idSeta1_poruka = 'Morate odabrati makar jedan set izbornih predmeta';
					M.toast({html: this.err_idSeta1_poruka, displayLength : this.toastTimeout});
					return;
				}
			}
			this.err_idSeta1 = true;
			this.err_idSeta1_poruka = '';

			if (this.prijavaskola.idSeta2){
				if (this.prijavaskola.idSeta2 === this.prijavaskola.idSeta1){
					this.err_idSeta2 = true;
					this.err_idSeta2_poruka = 'Morate odabrati različite setove izbornih predmeta';
					M.toast({html: this.err_idSeta2_poruka, displayLength : this.toastTimeout});
					return;
				}
			}
			this.err_idSeta2 = true;
			this.err_idSeta2_poruka = '';

			if (this.prijavaskola.idSeta3){
				if (this.prijavaskola.idSeta3 === this.prijavaskola.idSeta1 || this.prijavaskola.idSeta3 === this.prijavaskola.idSeta2){
					this.err_idSeta3 = true;
					this.err_idSeta3_poruka = 'Morate odabrati različite setove izbornih predmeta';
					M.toast({html: this.err_idSeta2_poruka, displayLength : this.toastTimeout});
					return;
				}
			}
			this.err_idSeta3 = true;
			this.err_idSeta3_poruka = '';

			let url = 'prijave/izmijeniprijavuisrednje';
			if (this.prijavaskola.id === undefined || this.prijavaskola.id == null || this.prijavaskola.id == ''){
				url = 'prijave/dodajprijavuisrednje';
			}
			this.loading = true;
			//console.log(this.prijavaskola);
			setTimeout(() => {				
			
				this.http.post(url, this.prijavaskola)
					.subscribe(data => {
						this.msg = data;
						this.loading = false;

						if (this.msg.id == 1) {
							if (this.prijavaskola.id === undefined || this.prijavaskola.id == null || this.prijavaskola.id == '') {
								this.prijavaskola.id = this.msg.newId;
							}
							//this.activeModal.close(this.prijavaskola);
							//this.globalSrvc.showSuccessModal();
							//M.toast({html : 'Uspješno', displayLength : this.toastTimeout});

							if (this.onInsertISrednje)
								this.onInsertISrednje.emit(this.prijavaskola);
						}
						else {
							//this.globalSrvc.showErrorModal(this.msg.text);
							M.toast({html:this.msg.text, displayLength : this.toastTimeout});
						}
					}
					, error => {
						this.loading = false;
						//this.globalSrvc.showGenericErrorModal();
					}
				);
			}, 1000);			
		}
		catch (err) {

		}
	}
	crps_data: any = null;

	centralniregistar() {

		/*/
		if (this.crps_data && this.crps_data.errMsg){
			this.err_jmbg = true;
			this.err_jmbg_poruka = this.crps_data.errMsg + '!';
			M.toast({html: this.crps_data.errMsg + '!', displayLength : 4000});
			//return;
		}

		this.prijavaskola.ime = 'Marko';
		this.prijavaskola.prezime = 'Markovic';
		this.prijavaskola.imeOca = 'Milo';
		this.prijavaskola.imeMajke = 'Milodarka';
		this.prijavaskola.opstina = 'Podgorica';
		this.prijavaskola.adresa = 'Krusi b.b.';
		this.prijavaskola.datumRodjenja = '02.02.2015';
		
		this.prijavaskola.mjestoRodjenja = 'Podgorica';
		this.prijavaskola.opstinaRodjenja = 'Podgorica';
		this.prijavaskola.drzavaRodjenja = 'Crna Gora';
		this.prijavaskola.mjestoPrebivalista = 'Podgorica';
		this.prijavaskola.pol = 'M';
		this.prijavaskola.drzavljanstvo = '1';
		this.prijavaskola.vecPohadjalo = '1';
		this.prijavaskola.porodicaMop = '0';
		//console.log('prijava skola', this.prijavaskola);
		setTimeout(() => {
			M.updateTextFields();
		}, 100);
		/**/

		/**/ 

		this.loading = true;

		setTimeout(() => {			
		
			this.http.get(this.globalSrvc.rootUrl + 'prijave/srednja/registri/pretraga/jmbg/', { params: { jmbg: this.prijavaskola.jmbg, tip:'skola'} })
				.subscribe(data => {
					this.loading = false;			
					this.crps_data = data;
					
					if (this.crps_data && this.crps_data.errMsg){
						this.err_jmbg = true;
						this.err_jmbg_poruka = this.crps_data.errMsg + '!';
						M.toast({html: this.crps_data.errMsg + '!', displayLength : 4000});
						return;
					}
					
					if (this.crps_data.ime != null && this.crps_data.ime != '') {						
						this.prijavaskola.ime = this.crps_data.ime;
						this.prijavaskola.prezime = this.crps_data.prezime;
						this.prijavaskola.imeOca = this.crps_data.imeOca;
						this.prijavaskola.imeMajke = this.crps_data.imeMajke;
						this.prijavaskola.adresa = this.crps_data.adresa;
						this.prijavaskola.opstina = this.crps_data.opstina;

						if (!(this.crps_data.datumRodjenja === undefined) && this.crps_data.datumRodjenja != null && this.crps_data.datumRodjenja != '')
							this.prijavaskola.datumRodjenja = this.crps_data.datumRodjenja;
						
						this.prijavaskola.mjestoRodjenja = this.crps_data.mjestoRodjenja;
						this.prijavaskola.opstinaRodjenja = this.crps_data.opstinaRodjenja;
						this.prijavaskola.drzavaRodjenja = this.crps_data.drzavaRodjenja;
						this.prijavaskola.mjestoPrebivalista = this.crps_data.mjestoPrebivalistaPunNaziv;
						this.prijavaskola.pol = this.crps_data.pol;
						this.prijavaskola.drzavljanstvo = this.crps_data.drzavljanstvo;
						this.prijavaskola.etnika = this.crps_data.etnika;
						this.prijavaskola.imaRjesenjeKat = this.crps_data.imaRjesenjeKat;
						this.prijavaskola.idGrupeStrani = this.crps_data.idGrupeStrani; 
						this.prijavaskola.idUstanoveOsnovno = this.crps_data.idUstanoveOsnovno;
						this.prijavaskola.idGrupePrviStrani = this.crps_data.idGrupePrviStrani;
						setTimeout(() => {
							M.updateTextFields();
						}, 100);

						this.err_jmbg = false;
						this.err_jmbg_poruka = '';
					}
					else {
						this.err_jmbg = true;
						this.err_jmbg_poruka = 'U registrima nije nađen zadati JMB!';
						M.toast({html:  'U registrima nije nađen zadati JMB!', displayLength : 4000});

						this.prijavaskola.ime = '';
						this.prijavaskola.prezime = '';
						this.prijavaskola.imeOca = '';
						this.prijavaskola.imeMajke = '';
						this.prijavaskola.adresa = '';
						this.prijavaskola.datumRodjenja = '';					
						this.prijavaskola.mjestoRodjenja = '';
						this.prijavaskola.opstinaRodjenja = '';
						this.prijavaskola.drzavaRodjenja = '';
						this.prijavaskola.mjestoPrebivalista = '';
						this.prijavaskola.pol = '';
						this.prijavaskola.drzavljanstvo = '';
						this.prijavaskola.vecPohadjalo = '';
						this.prijavaskola.porodicaMop = '';
						return;
					}
				}
				, error => {
					this.loading = false;
				}
			);
		}, 500);
		/**/ 
	}

	cancel(){
		if (this.onCancel)
			this.onCancel.emit(null);
	}

	jmbgChanged(){
		this.prijavaskola.ime = '';
		this.prijavaskola.prezime = '';
		this.prijavaskola.imeOca = '';
		this.prijavaskola.imeMajke = '';
		this.prijavaskola.adresa = '';
		this.prijavaskola.datumRodjenja = '';					
		this.prijavaskola.mjestoRodjenja = '';
		this.prijavaskola.opstinaRodjenja = '';
		this.prijavaskola.drzavaRodjenja = '';
		this.prijavaskola.mjestoPrebivalista = '';
		this.prijavaskola.pol = '';
		this.prijavaskola.drzavljanstvo = '';
		this.prijavaskola.vecPohadjalo = '';
		this.prijavaskola.porodicaMop = '';

		setTimeout(() => {
			M.updateTextFields();
		}, 100);
	}

}


