import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../global.service';
import * as M from "materialize-css/dist/js/materialize";
import * as $ from 'jquery';
import { trimTrailingNulls } from '@angular/compiler/src/render3/view/util';

@Component({
	selector: 'unos-prijava-vrtic',
	templateUrl: './unos-prijava-vrtic.component.html',
	styleUrls: ['./unos-prijava-vrtic.component.css']
})
export class UnosPrijavaVrticComponent implements OnInit {

	constructor(private http: HttpClient, public globalSrvc: GlobalService) { }

	@Input() id;
	@Input() novi;
	@Input() jedinica;
	@Input() prekoportala;

	@Output() onInsertVrtic = new EventEmitter<any>();
	@Output() onCancel = new EventEmitter<any>();

	prijavapred: any = { 'id': '', 'idUstanove': '', 'brojJedinice': '', 'jmbg': '', 'ime': '', 'prezime': '', 'adresa': '', 'datumRodjenja': '', 'mjestoRodjenja': '', 'telefon': '', 'eMail': '', 'pol': '', 'brClabPorodice': '', 'brDjecePredUzrast': '', 'roditeljZiviId': '', 'roditeljZiviNaziv': '', 'vecPohadjalo': '', 'korisnikMaterijalno': '', 'imeOca': '', 'imeMajke': '', 'nivoObrOtac': '', 'nivoObrMajka': '', 'poslodavacOtac': '', 'poslodavacMajka': '', 'odabraniProgram': '', 'napomenaRoditelja': '', 'ezh': '', 'djelBroj': '', 'prekoPortala': '', idJezika : '', jezici : [], 'jediniceStavke': [], 'roditeljZiviStavke': [], 'odabraniProgramStavke': [], imeStaratelja : '', drzavaRodjenja : '', drzavljanstvo : '', altTel : ''};

	jediniceStavke: any = [];
	jeziciStavke : any = [];
	err_brojJedinice = false;
	err_brojJedinice_poruka = '';
	err_jmbg = false;
	err_jmbg_poruka = '';
	err_ime = false;
	err_ime_poruka = '';
	err_prezime = false;
	err_prezime_poruka = '';
	err_adresa = false;
	err_adresa_poruka = '';
	//datumRodjenja : NgbDateStruct;
	err_datumRodjenja = false;
	err_datumRodjenja_poruka = '';
	err_telefon = false;
	err_telefon_poruka = '';
	err_email = false;
	err_email_poruka = '';
	err_pol = false;
	err_pol_poruka = '';
	err_brClabPorodice = false;
	err_brClabPorodice_poruka = '';
	err_brDjecePredUzrast = false;
	err_brDjecePredUzrast_poruka = '';
	roditeljZiviStavke: any = [];
	err_roditeljZiviId = false;
	err_roditeljZiviId_poruka = '';
	err_vecPohadjalo = false;
	err_vecPohadjalo_poruka = '';
	err_korisnikMaterijalno = false;
	err_korisnikMaterijalno_poruka = '';
	err_imeOca = false;
	err_imeOca_poruka = '';
	err_imeMajke = false;
	err_imeMajke_poruka = '';
	err_nivoObrOtac = false;
	err_nivoObrOtac_poruka = '';
	err_nivoObrMajka = false;
	err_nivoObrMajka_poruka = '';
	err_poslodavacOtac = false;
	err_poslodavacOtac_poruka = '';
	err_poslodavacMajka = false;
	err_poslodavacMajka_poruka = '';
	odabraniProgramStavke: any = [];
	err_odabraniProgram = false;
	err_odabraniProgram_poruka = '';
	saveDisabled = false;
	loading = false;
	msg: any;
	err_ustanova_poruka = '';
	err_ustanova = false;
	err_jedinica_poruka = '';
	err_jedinica = false;
	err_telefon_otac = false;
	err_telefon_otac_poruka = '';
	err_ime_staratelja = false;
	err_ime_staratelja_poruka = '';
	err_jezik = false;
	err_jezik_poruka = '';

	odlozenUpisStavke: any = [{ 'id': '', 'naziv': '', jeOdabran: false }, { 'id': '1', 'naziv': 'Prijedlog roditelja', jeOdabran: false }, { 'id': '2', 'naziv': 'Mišljenje nadležne zdravstvene ustanove', jeOdabran: false }, { 'id': '3', 'naziv': 'Komisija za upis djece u školu', jeOdabran: false }];
	ziviSaStavke: any = [{ 'id': '', 'naziv': '', jeOdabran: false }, { 'id': '1', 'naziv': 'Sa oba roditelja', jeOdabran: false }, { 'id': '2', 'naziv': 'Sa jednim roditeljem', jeOdabran: false }, { 'id': '3', 'naziv': 'Sa starateljem/hraniteljem', jeOdabran: false }, { 'id': '4', 'naziv': 'Dijete živi u ustanovi za nezbrinutu djecu', jeOdabran: false }];
	nivoKvalifikacije: any = [{ 'id': '', 'naziv': '', jeOdabran: false },
	{ 'id': 'Nepotpuna osnovna škola', 'naziv': 'Nepotpuna osnovna škola', jeOdabran: false },
	{ 'id': 'Osnovna škola', 'naziv': 'Osnovna škola', jeOdabran: false },
	{ 'id': 'Srednja škola', 'naziv': 'Srednja škola', jeOdabran: false },
	{ 'id': 'Viša škola', 'naziv': 'Viša škola', jeOdabran: false },
	{ 'id': 'Visoka škola', 'naziv': 'Visoka škola', jeOdabran: false },
	{ 'id': 'Magistar nauka', 'naziv': 'Magistar nauka', jeOdabran: false },
	{ 'id': 'Doktor nauka', 'naziv': 'Doktor nauka', jeOdabran: false }
	];
	/*odbraniProgramstavke : any = [{ 'id': '', 'naziv': '', jeOdabran: false },
	{ 'id': '1', 'naziv': 'PRIMARNI - cjelodnevni (u trajanju od 6 do 9 časova)', jeOdabran: false },
	{ 'id': '2', 'naziv': 'PRIMARNI - poludnevni (u trajanju od 4 do 6 časova)', jeOdabran: false },
	{ 'id': '3', 'naziv': 'PRIMARNI - popodnevni (u trajanju od 5 do 7 časova)', jeOdabran: false },
	{ 'id': '4', 'naziv': 'TROSATNI - program pripreme djece za osnovnu školu (u trajanju od 3 časa)', jeOdabran: false },
	];*/
	odbraniProgramstavke : any = [];

	brClanPorodiceStavke: any = [{ 'id': '', 'naziv': '', jeOdabran: false }];
	brDjeceStavke: any = [{ 'id': '', 'naziv': '', jeOdabran: false }];
	dijetePoRodjenuStavke : any = [{ 'id': '', 'naziv': '', jeOdabran: false }];

	jmbgvalid = false;
	skole: any = [];
	jedinice: any = [];

	ngOnInit() {
		this.initSifrarnici();
		
		if (this.id)
			this.ucitaj();
		else
			this.ucitajSkole();

		setTimeout(() => {
			let elems = document.querySelectorAll('.tooltipped');
			let instances = M.Tooltip.init(elems, {});
			//console.log('elems', elems);
			elems = document.querySelectorAll('.datepicker');
			instances = M.Datepicker.init(elems, {
				firstDay: true,
				format: 'dd.mm.yyyy',
				i18n: {
					months: ["Januar", "Februar", "Mart", "April", "Maj", "Jun", "Jul", "Avgust", "Septembar", "Oktobar", "Novembar", "Decembar"],
					monthsShort: ["Jan", "Feb", "Mar", "Apr", "Maj", "Jun", "Jul", "Avg", "Sep", "Okt", "Nov", "Dec"],
					weekdays: ["Nedjelja", "Ponedjeljak", "Utorak", "Srijeda", "Četvrtak", "Petak", "Subota"],
					weekdaysShort: ["Ned", "Pon", "Uto", "Sri", "Čet", "Pet", "Sub"],
					weekdaysAbbrev: ["N", "P", "U", "S", "Č", "P", "S"]
				}
			});
		}, 1000);
	}

	initSifrarnici(){
		for(let i = 1 ; i <= 22; i++)
			this.brClanPorodiceStavke.push({ 'id': i + '', 'naziv': i + '', jeOdabran: false });

		for(let i = 1 ; i <= 20; i++)
			this.brDjeceStavke.push({ 'id': i + '', 'naziv': i + '', jeOdabran: false });
		
		for(let i = 1 ; i <= 20; i++)
			this.dijetePoRodjenuStavke.push({ 'id': i + '', 'naziv': i + '', jeOdabran: false });
	}

	jmbgfocuslost(event) {
		//console.log('focus lost', this.prijavapred.jmbg);
	}

	ucitajSkole() {
		this.loading = true;
		this.http.get('prijave/vrtici', { params: { id: this.id } })
			.subscribe(data => {
				this.loading = false;
				this.skole = data;
				this.skole = this.skole.ustanove;
				//console.log(this.skole);
				if (this.id)
					this.skolaselected(null);

			}
				, error => {
					this.loading = false;
				});
	}

	ucitajPrograme() {
		this.loading = true;
		this.odbraniProgramstavke = [];

		this.http.get('prijave/predskolsko/programi', { params: { idUstanove: this.prijavapred.idUstanove, brojJedinice : this.prijavapred.brojJedinice } })
		.subscribe(data => {
			this.loading = false;
			this.odbraniProgramstavke = data;
		}
		, error => {
			this.loading = false;
		});
	}

	ucitajJezike() {
		this.loading = true;
		this.jeziciStavke = [];

		this.http.get('prijave/skola/jezici', { params: { idUstanove: this.prijavapred.idUstanove} })
		.subscribe(data => {
			this.loading = false;
			this.jeziciStavke = data;

			if (this.jeziciStavke && this.jeziciStavke.length == 1)
				this.prijavapred.idJezika = this.jeziciStavke[0].id;
			else 
				this.prijavapred.idJezika = null;
		}
		, error => {
			this.loading = false;
		});
	}

	ucitaj() {
		this.loading = true;
		this.odbraniProgramstavke = [];

		this.http.get(this.globalSrvc.rootUrl + 'prijave/predskolsko/pretraga/detaljno/', { params: { id: this.id } })
			.subscribe(data => {
				this.loading = false;
				this.prijavapred = data;
				this.odbraniProgramstavke = this.prijavapred.odabraniProgramStavke;
				this.jeziciStavke = this.prijavapred.jezici;
				//console.log('this.prijavapred', this.prijavapred);			
				setTimeout(() => {
					M.updateTextFields();
				}, 100);
				this.ucitajSkole();
			}
				, error => {
					this.loading = false;
				});
	}
	changedate(val) {
		//console.log(val);
	}

	skolaselected(event) {
		let skola = this.skole.find(item => item.id == this.prijavapred.idUstanove);
		this.jedinice = skola.jedinice;

		if (this.id)
			 return;
			 
		this.prijavapred.brojJedinice = null;
		this.prijavapred.odabraniProgram = null;
		this.odbraniProgramstavke = [];
		
		//this.prijavapred.vecPohadjalo = null;

		this.ucitajJezike();
	}

	jedinicaselected(event){
		if (this.id)
			 return;
		//this.prijavapred.vecPohadjalo = null;
		this.prijavapred.odabraniProgram = null;
		this.odabraniProgramStavke = [];
		this.ucitajPrograme();
	}

	printobj() {
		//console.log(this.prijavapred);
	}
	toastTimeout = 3000;

	savedata() {
		try {
			if (this.prijavapred.idUstanove === undefined || this.prijavapred.idUstanove == null || this.prijavapred.idUstanove == '') {
				this.err_ustanova = true;
				this.err_ustanova_poruka = 'Morate odabrati ustanovu';
				M.toast({ html: this.err_ustanova_poruka, displayLength: this.toastTimeout });
				return;
			}
			this.err_ustanova = false;
			this.err_ustanova_poruka = '';

			if (this.prijavapred.brojJedinice === undefined || this.prijavapred.brojJedinice == null || this.prijavapred.brojJedinice == '') {
				this.err_jedinica = true;
				this.err_jedinica_poruka = 'Morate odabrati jedinicu';
				M.toast({ html: this.err_jedinica_poruka, displayLength: this.toastTimeout });
				return;
			}
			this.err_jedinica = false;
			this.err_jedinica_poruka = '';

			if (this.prijavapred.idJezika === undefined || this.prijavapred.idJezika == null || this.prijavapred.idJezika == '') {
				this.err_jezik = true;
				this.err_jezik_poruka = 'Morate definisati polje Nastavni jezik';
				M.toast({html: this.err_jezik_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_jezik = false;
			this.err_jezik_poruka = '';
			
			if (this.prijavapred.jmbg === undefined || this.prijavapred.jmbg == null || this.prijavapred.jmbg == '') {
				this.err_jmbg = true;
				this.err_jmbg_poruka = 'Morate definisati polje JMB djeteta';
				M.toast({ html: this.err_jmbg_poruka, displayLength: this.toastTimeout });
				return;
			}
			this.err_jmbg = false;
			this.err_jmbg_poruka = '';

			if (this.prijavapred.ime === undefined || this.prijavapred.ime == null || this.prijavapred.ime == '') {
				this.err_ime = true;
				this.err_ime_poruka = 'Morate definisati polje Ime. Ovaj podatak se preuzima iz registara klikom na dugme pored polja JMB.';
				M.toast({ html: this.err_ime_poruka, displayLength: this.toastTimeout });
				return;
			}
			this.err_ime = false;
			this.err_ime_poruka = '';

			if (this.prijavapred.prezime === undefined || this.prijavapred.prezime == null || this.prijavapred.prezime == '') {
				this.err_prezime = true;
				this.err_prezime_poruka = 'Morate definisati polje Prezime. Ovaj podatak se preuzima iz registara klikom na dugme pored polja JMB.';
				M.toast({ html: this.err_prezime_poruka, displayLength: this.toastTimeout });
				return;
			}
			this.err_prezime = false;
			this.err_prezime_poruka = '';

			if (this.prijavapred.datumRodjenja === undefined || this.prijavapred.datumRodjenja == null) {
				this.err_datumRodjenja = true;
				this.err_datumRodjenja_poruka = 'Morate definisati polje Datum rođenja. Ovaj podatak se preuzima iz registara klikom na dugme pored polja JMB.';
				M.toast({ html: this.err_datumRodjenja_poruka, displayLength: this.toastTimeout });
				return;
			}
			this.err_datumRodjenja = false;
			this.err_datumRodjenja_poruka = '';

			if (this.prijavapred.adresa === undefined || this.prijavapred.adresa == null || this.prijavapred.adresa == '') {
				this.err_adresa = true;
				this.err_adresa_poruka = 'Morate definisati polje Adresa. Ovaj podatak se preuzima iz registara klikom na dugme pored polja JMB.';
				M.toast({ html: this.err_adresa_poruka, displayLength: this.toastTimeout });
				return;
			}
			this.err_adresa = false;
			this.err_adresa_poruka = '';

			if (this.prijavapred.telefon === undefined || this.prijavapred.telefon == null || this.prijavapred.telefon == '') {
				this.err_telefon = true;
				this.err_telefon_poruka = 'Morate definisati polje Telefon roditelja/staratelja/hranitelja';
				M.toast({ html: this.err_telefon_poruka, displayLength: this.toastTimeout });
				return;
			}
			this.err_telefon = false;
			this.err_telefon_poruka = '';

			if (!this.prijavapred.eMail) {
				this.err_email = true;
				this.err_email_poruka = 'Morate definisati polje E-mail roditelja/staratelja/hranitelja';
				M.toast({ html: this.err_telefon_poruka, displayLength: this.toastTimeout });
				return;
			}
			this.err_email = false;
			this.err_email_poruka = '';

			if (this.prijavapred.pol === undefined || this.prijavapred.pol == null || this.prijavapred.pol == '') {
				this.err_pol = true;
				this.err_pol_poruka = 'Morate definisati polje Pol. Ovaj podatak se preuzima iz registara klikom na dugme pored polja JMB.';
				M.toast({ html: this.err_pol_poruka, displayLength: this.toastTimeout });
				return;
			}
			this.err_pol = false;
			this.err_pol_poruka = '';

			if (this.prijavapred.brClabPorodice === undefined || this.prijavapred.brClabPorodice == null || this.prijavapred.brClabPorodice == ''){
				this.err_brClabPorodice = true;
				this.err_brClabPorodice_poruka = 'Morate definisati polje Broj članova porodice';
				M.toast({ html: this.err_brClabPorodice_poruka, displayLength: this.toastTimeout });
				return;
			}
			this.err_brClabPorodice = false;
			this.err_brClabPorodice_poruka = '';

			if (!(this.prijavapred.brClabPorodice === undefined || this.prijavapred.brClabPorodice == null || this.prijavapred.brClabPorodice == '')) {
				if (!this.globalSrvc.CheckNumber(this.prijavapred.brClabPorodice)) {
					this.err_brClabPorodice = true;
					this.err_brClabPorodice_poruka = 'Nevalidan format za polje Broj članova porodice';
					M.toast({ html: this.err_brClabPorodice_poruka, displayLength: this.toastTimeout });
					return;
				}
			}
			this.err_brClabPorodice = false;
			this.err_brClabPorodice_poruka = '';

			if (this.prijavapred.brDjecePredUzrast === undefined || this.prijavapred.brDjecePredUzrast == null || this.prijavapred.brDjecePredUzrast == '') {
				this.err_brDjecePredUzrast = true;
				this.err_brDjecePredUzrast_poruka = 'Morate definisati polje Broj djece pred. uzrasta u porodici';
				M.toast({ html: this.err_brDjecePredUzrast_poruka, displayLength: this.toastTimeout });
				return;
			}
			this.err_brDjecePredUzrast = false;
			this.err_brDjecePredUzrast_poruka = '';

			if (!(this.prijavapred.brDjecePredUzrast === undefined || this.prijavapred.brDjecePredUzrast == null || this.prijavapred.brDjecePredUzrast == '')) {
				if (!this.globalSrvc.CheckInt(this.prijavapred.brDjecePredUzrast)) {
					this.err_brDjecePredUzrast = true;
					this.err_brDjecePredUzrast_poruka = 'Nevalidan format za polje Broj djece pred. uzrasta u porodici';
					M.toast({ html: this.err_brDjecePredUzrast_poruka, displayLength: this.toastTimeout });
					return;
				}
			}
			this.err_brDjecePredUzrast = false;
			this.err_brDjecePredUzrast_poruka = '';

			if (this.prijavapred.roditeljZiviId === undefined || this.prijavapred.roditeljZiviId == null || this.prijavapred.roditeljZiviId == ''){
				this.err_roditeljZiviId = true;
				this.err_roditeljZiviId_poruka = 'Morate definisati polje Dijete živi sa';
				M.toast({html: this.err_roditeljZiviId_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_roditeljZiviId = false;
			this.err_roditeljZiviId_poruka = '';

			if (this.prijavapred.roditeljZiviId == 3){
				if (this.prijavapred.imeStaratelja === undefined || this.prijavapred.imeStaratelja == null || this.prijavapred.imeStaratelja == '') {
					this.err_ime_staratelja = true;
					this.err_ime_staratelja_poruka = 'Ime i prezime staratelja/hranitelja';
					M.toast({html: this.err_ime_staratelja_poruka, displayLength : this.toastTimeout});
					return;
				}
				this.err_ime_staratelja = false;
				this.err_ime_staratelja_poruka = '';
			}

			if (this.prijavapred.vecPohadjalo === undefined || this.prijavapred.vecPohadjalo == null || this.prijavapred.vecPohadjalo == ''){
				this.err_vecPohadjalo = true;
				this.err_vecPohadjalo_poruka = 'Morate definisati polje Dijete je već pohađalo ustanovu. Ovaj podatak se preuzima iz registara klikom na dugme pored polja JMB.';
				M.toast({html: this.err_vecPohadjalo_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_vecPohadjalo = false;
			this.err_vecPohadjalo_poruka = '';
	
			/*if (this.prijavapred.korisnikMaterijalno === undefined || this.prijavapred.korisnikMaterijalno == null || this.prijavapred.korisnikMaterijalno == ''){
				this.err_korisnikMaterijalno = true;
				this.err_korisnikMaterijalno_poruka = 'Morate definisati polje Porodica prima mat. obezb. Ovaj podatak se preuzima iz registara klikom na dugme pored polja JMB.';
				M.toast({html: this.err_korisnikMaterijalno_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_korisnikMaterijalno = false;
			this.err_korisnikMaterijalno_poruka = '';
	
			if (this.prijavapred.imeOca === undefined || this.prijavapred.imeOca == null || this.prijavapred.imeOca == ''){
				this.err_imeOca = true;
				this.err_imeOca_poruka = 'Morate definisati polje Ime oca. Ovaj podatak se preuzima iz registara klikom na dugme pored polja JMB.';
				M.toast({html: this.err_imeOca_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_imeOca = false;
			this.err_imeOca_poruka = '';
	
			if (this.prijavapred.imeMajke === undefined || this.prijavapred.imeMajke == null || this.prijavapred.imeMajke == ''){
				this.err_imeMajke = true;
				this.err_imeMajke_poruka = 'Morate definisati polje Ime majke. Ovaj podatak se preuzima iz registara klikom na dugme pored polja JMB.';
				M.toast({html: this.err_imeMajke_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_imeMajke = false;
			this.err_imeMajke_poruka = '';
			*/
			if (this.prijavapred.odabraniProgram === undefined || this.prijavapred.odabraniProgram == null || this.prijavapred.odabraniProgram == '') {
				this.err_odabraniProgram = true;
				this.err_odabraniProgram_poruka = 'Morate definisati polje Odabrani program';
				M.toast({html: this.err_odabraniProgram_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_odabraniProgram = false;
			this.err_odabraniProgram_poruka = '';

			let url = 'prijave/predskolsko/dodaj';

			this.loading = true;

			setTimeout(() => {

				this.http.post(url, this.prijavapred)
					.subscribe(data => {
						this.msg = data;
						this.loading = false;

						if (this.msg.id == 1) {
							if (this.prijavapred.id === undefined || this.prijavapred.id == null || this.prijavapred.id == '') {
								this.prijavapred.id = this.msg.newId;
							}
							//this.activeModal.close(this.prijavapred);
							//this.globalSrvc.showSuccessModal();
							//M.toast({ html: 'Uspješno', displayLength: this.toastTimeout });

							if (this.onInsertVrtic)
								this.onInsertVrtic.emit(this.prijavapred);
						}
						else {
							//this.globalSrvc.showErrorModal(this.msg.text);
							M.toast({ html: this.msg.text, displayLength: this.toastTimeout });
						}
					}
						, error => {
							this.loading = false;
							//this.globalSrvc.showGenericErrorModal();
						}
					);
			}, 1000);
		}
		catch (err) {

		}
	}
	crps_data: any = null;

	centralniregistar() {
		if (this.prijavapred.idUstanove === undefined || this.prijavapred.idUstanove == null || this.prijavapred.idUstanove == '') {
			this.err_ustanova = true;
			this.err_ustanova_poruka = 'Morate odabrati ustanovu';
			M.toast({ html: this.err_ustanova_poruka, displayLength: this.toastTimeout });
			return;
		}
		this.err_ustanova = false;
		this.err_ustanova_poruka = '';

		if (this.prijavapred.brojJedinice === undefined || this.prijavapred.brojJedinice == null || this.prijavapred.brojJedinice == '') {
			this.err_jedinica = true;
			this.err_jedinica_poruka = 'Morate odabrati jedinicu';
			M.toast({ html: this.err_jedinica_poruka, displayLength: this.toastTimeout });
			return;
		}
		this.err_jedinica = false;
		this.err_jedinica_poruka = '';

		/*/
		if (this.crps_data && this.crps_data.errMsg){
			this.err_jmbg = true;
			this.err_jmbg_poruka = this.crps_data.errMsg + '!';
			M.toast({html: this.crps_data.errMsg + '!', displayLength : 4000});
			//return;
		}
		this.prijavapred.ime = 'Marko';
		this.prijavapred.prezime = 'Markovic';
		this.prijavapred.imeOca = 'Milo';
		this.prijavapred.imeMajke = 'Milodarka';
		this.prijavapred.adresa = 'Krusi b.b.';
		this.prijavapred.datumRodjenja = '02.02.2015';

		this.prijavapred.mjestoRodjenja = 'Podgorica';
		this.prijavapred.opstinaRodjenja = 'Podgorica';
		this.prijavapred.drzavaRodjenja = 'Crna Gora';
		this.prijavapred.mjestoPrebivalista = 'Podgorica';
		this.prijavapred.pol = 'M';
		this.prijavapred.drzavljanstvo = '1';
		this.prijavapred.vecPohadjalo = '0';
		//this.prijavapred.korisnikMaterijalno = '0';
		//console.log('prijava skola', this.prijavapred);
		if (this.prijavapred.ime) $("#lbl-ime").addClass("active");
		if (this.prijavapred.prezime) $("#lbl-prezime").addClass("active");
		if (this.prijavapred.adresa) $("#lbl-adresa").addClass("active");
		if (this.prijavapred.datumRodjenja) $("#lbl-d-rodjenja").addClass("active");
		if (this.prijavapred.mjestoRodjenja) $("#lbl-m-rodjenja").addClass("active");
		if (this.prijavapred.imeOca) $("#lbl-ime-oca").addClass("active");
		if (this.prijavapred.imeMajke) $("#lbl-ime-majke").addClass("active");
		/**/
		
		/**/ 
		this.loading = true;

		setTimeout(() => {
			this.http.get(this.globalSrvc.rootUrl + 'prijave/registri/pretraga/jmbg/', { params: { jmbg: this.prijavapred.jmbg, tip : 'vrtic'} })
				.subscribe(data => {
					this.loading = false;
					//console.log(data);					
					this.crps_data = data;
					
					
					if (this.crps_data && this.crps_data.errMsg){
						this.err_jmbg = true;
						this.err_jmbg_poruka = this.crps_data.errMsg + '!';
						M.toast({html: this.crps_data.errMsg + '!', displayLength : 4000});
						return;
					}
					
					if (this.crps_data.ime != null && this.crps_data.ime != '') {
						
						this.prijavapred.ime = this.crps_data.ime;
						this.prijavapred.prezime = this.crps_data.prezime;
						this.prijavapred.imeOca = this.crps_data.imeOca;
						this.prijavapred.imeMajke = this.crps_data.imeMajke;
						this.prijavapred.adresa = this.crps_data.adresa;

						if (!(this.crps_data.datumRodjenja === undefined) && this.crps_data.datumRodjenja != null && this.crps_data.datumRodjenja != '')
							this.prijavapred.datumRodjenja = this.crps_data.datumRodjenja;
						
						this.prijavapred.mjestoRodjenja = this.crps_data.mjestoRodjenja;
						this.prijavapred.opstinaRodjenja = this.crps_data.opstinaRodjenja;
						this.prijavapred.drzavaRodjenja = this.crps_data.drzavaRodjenja;
						this.prijavapred.mjestoPrebivalista = this.crps_data.mjestoPrebivalistaPunNaziv;
						this.prijavapred.pol = this.crps_data.pol;
						this.prijavapred.drzavljanstvo = this.crps_data.drzavljanstvo;
						this.prijavapred.vecPohadjalo = this.crps_data.vecPohadjalo;

						if (this.prijavapred.ime) $( "#lbl-ime" ).addClass( "active" );
						if (this.prijavapred.prezime) $( "#lbl-prezime" ).addClass( "active" );
						if (this.prijavapred.adresa) $( "#lbl-adresa" ).addClass( "active" );
						if (this.prijavapred.datumRodjenja) $( "#lbl-d-rodjenja" ).addClass( "active" );
						if (this.prijavapred.mjestoRodjenja) $( "#lbl-m-rodjenja" ).addClass( "active" );
						if (this.prijavapred.imeOca) $( "#lbl-ime-oca" ).addClass( "active" );
						if (this.prijavapred.imeMajke) $( "#lbl-ime-majke" ).addClass( "active" );
						if (this.prijavapred.drzavaRodjenja) {$( "#lbl-drzrodjenja" ).addClass( "active" );}
						if (this.prijavapred.drzavljanstvo) $( "#lbl-drzavaljanstvo" ).addClass( "active" );

						this.err_jmbg = false;
						this.err_jmbg_poruka = '';
					}
					else {
						this.err_jmbg = true;
						this.err_jmbg_poruka = 'U registrima nije nađen zadati JMB!';
						M.toast({html:  'U registrima nije nađen zadati JMB!', displayLength : 4000});

						this.prijavapred.ime = '';
						this.prijavapred.prezime = '';
						this.prijavapred.imeOca = '';
						this.prijavapred.imeMajke = '';
						this.prijavapred.adresa = '';
						this.prijavapred.datumRodjenja = '';					
						this.prijavapred.mjestoRodjenja = '';
						this.prijavapred.opstinaRodjenja = '';
						this.prijavapred.drzavaRodjenja = '';
						this.prijavapred.mjestoPrebivalista = '';
						this.prijavapred.pol = '';
						this.prijavapred.drzavljanstvo = '';
						this.prijavapred.vecPohadjalo = '';
						this.prijavapred.porodicaMop = '';
						return;
					}
				}
					, error => {
						this.loading = false;
					}
				);
		}, 500);
		/**/
	}

	cancel() {
		if (this.onCancel)
			this.onCancel.emit(null);
	}

	jmbgChanged(){
		this.prijavapred.ime = '';
		this.prijavapred.prezime = '';
		this.prijavapred.imeOca = '';
		this.prijavapred.imeMajke = '';
		this.prijavapred.adresa = '';
		this.prijavapred.datumRodjenja = '';					
		this.prijavapred.mjestoRodjenja = '';
		this.prijavapred.opstinaRodjenja = '';
		this.prijavapred.drzavaRodjenja = '';
		this.prijavapred.mjestoPrebivalista = '';
		this.prijavapred.pol = '';
		this.prijavapred.drzavljanstvo = '';
		this.prijavapred.vecPohadjalo = '';
		this.prijavapred.porodicaMop = '';

		setTimeout(() => {
			M.updateTextFields();
		}, 100);
	}

}
